import { Title, Meta } from "@solidjs/meta";

import { useTranslation } from "#root/domain/i18n";
import { Show, mergeProps } from "solid-js";

type SEOProps = {
  title?: string;
  description?: string;
  noIndex?: boolean;
};

export function SEO(_props: SEOProps) {
  const { t, locale } = useTranslation();
  const props = mergeProps(_props, { noIndex: false });

  const title = () => props.title ?? t("head.defaultTitle");
  const description = () => props.description ?? t("head.defaultDescription");
  const url = () => `https://asklive.co/${locale}`;
  const ogImage = "https://asklive.co/images/og.png";

  return (
    <>
      <Title>{title()}</Title>
      <Meta name="description" content={description()} />
      <Show
        when={props.noIndex}
        fallback={<Meta name="robots" content="index" />}
      >
        <Meta name="robots" content="noindex" />
      </Show>
      <Meta property="og:type" content="website" />
      <Meta property="og:title" content={title()} />
      <Meta property="og:description" content={description()} />
      <Meta property="og:url" content={url()} />
      <Meta property="og:image" content={ogImage} />
      <Meta property="og:locale" content={locale} />
      <Meta property="og:image:url" content={ogImage} />
      <Meta property="og:image:width" content="1200" />
      <Meta property="og:image:height" content="630" />
      <Meta property="og:image:alt" content={t("head.ogImageAlt")} />
      <Meta name="twitter:card" content="summary_large_image" />
      <Meta name="twitter:site" content="@asklive_app" />
      <Meta name="twitter:creator" content="@asklive_app" />
      <Meta name="twitter:title" content={title()} />
      <Meta name="twitter:image" content={ogImage} />
      <Meta name="twitter:image:alt" content={t("head.ogImageAlt")} />
      <Meta name="twitter:description" content={description()} />
    </>
  );
}
